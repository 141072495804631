<template>
  <b-container fluid>
    <b-row md="12 m-n">
      <transition name="slide-fade">
        <b-col lg="3" md="6" v-show="getPurchase">
          <iq-card>
            <template v-slot:body>
              <div class="text-left"><span>Solicitudes de compras</span></div>
              <div class="d-flex justify-content-between align-items-center">
                <div class="value-box">
                  <h2 class="mb-0"><Counter :value="getPurchase" /></h2>
                </div>
                <div class="iq-iconbox iq-bg-danger">
                  <i class="ri-money-dollar-box-line pb-3" />
                </div>
              </div>
            </template>
          </iq-card>
        </b-col>
      </transition>
      <transition name="slide-fade">
        <div class="col-md-6 col-lg-3" v-show="getOrders">
          <div class="iq-card">
            <div class="iq-card-body">
              <div class="text-left"><span>Órdenes de compras</span></div>
              <div class="d-flex justify-content-between align-items-center">
                <div class="value-box">
                  <h2 class="mb-0"><Counter :value="getOrders" /></h2>
                </div>
                <div class="iq-iconbox iq-bg-info">
                  <i class="ri-shield-star-line pb-2"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
      <transition name="slide-fade">
        <div class="col-md-6 col-lg-3" v-show="getReception !== null">
          <div class="iq-card">
            <div class="iq-card-body">
              <div class="text-left"><span>Recepción de órdenes</span></div>
              <div class="d-flex justify-content-between align-items-center">
                <div class="value-box">
                  <h2 class="mb-0"><Counter :value="getReception" /></h2>
                </div>
                <div class="iq-iconbox iq-bg-success">
                  <i class="ri-shopping-bag-3-line pb-2"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
      <transition name="slide-fade">
        <div class="col-md-6 col-lg-3" v-show="getProducts">
          <div class="iq-card">
            <div class="iq-card-body">
              <div class="text-left"><span>Productos</span></div>
              <div class="d-flex justify-content-between align-items-center">
                <div class="value-box">
                  <h2 class="mb-0"><Counter :value="getProducts" /></h2>
                </div>
                <div class="iq-iconbox iq-bg-primary">
                  <i class="ri-record-circle-line pb-2"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </b-row>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:body>
            <div
              class="text-center"
              :style="' background: url('+bgImageURL+') no-repeat 0 0; background-size: cover;'"
            >
              <div
                class="sign-in-detail text-white"
                style="background: rgba(0, 0, 0, 0.6)"
              >
                <div>
                    <img
                      :src="require('../../assets/images/2.png')"
                      class="size img-fluid mb-4"
                      alt="logo"
                    />
                    <h1
                      class="mb-1 text-white font-weight-bolder text-monospace"
                    >
                      GIRA
                    </h1>
                    <h5 class="text-white text-monospace">
                      Colegio Santa Joaquina De Vedruna <br />
                      Puente Alto
                    </h5>
                  </div>
              </div>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../config/pluginInit'
import Swiper from '../../components/core/slider/Swiper'
import SwiperSlide from '../../components/core/slider/SwiperSlide'
import Counter from '../../components/core/counter/Counter'
import bgImage from '../../assets/images/home.jpg'
import {mapActions, mapGetters} from 'vuex'
export default {
  name: 'Dashboard2',
  components: { Counter, Swiper, SwiperSlide },
  async created () {
    this.$store.commit('stop')
    this.$store.commit("SET_BREADCRUMB", this.items);
    await this.fetchProducts({ search: null })
    await this.fetchPurchase()
    await this.fetchOrders()
    await this.fetchReception()
    core.index()
  },
  data () {
    return {
      orders: null,
      request: null,
      reception: null,
      bgImageURL: bgImage,
      options: {
        slidesPerView: 1,
        loop: true
      },
     items: [
        {
          html: 'Inicio',
          to: "/",
        },
        {
          text: "Escritorio",
          active: true,
        },
      ],
    }
  },
  methods: {
    ...mapActions({
      fetchProducts : 'products/listProducts',
      fetchPurchase : 'purchase/listPurchaseRequests',
      fetchOrders : 'orders/listPurchaseOrders',
      fetchReception : 'reception/listReception',

    })
  },
  computed: {
    ...mapGetters({
       getProducts : 'products/getLength',
       getPurchase : 'purchase/getPurchaseLength',
       getOrders : 'orders/getOrdersLength',
       getReception : 'reception/getRecepLength'
    })
  }
}
</script>
<style>
.size {
  width: 10%
}
.slide-fade-enter-active {
  transition: all 0.3s ease
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1)
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0
}
</style>
